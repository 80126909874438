.icon-anchor {
  text-decoration: none;
  color: black;
}

.nav-menu-anchor {
  text-decoration: none;
  color: black;
}

.nav-menu-anchor-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  padding: 5px;
}
