.information-wrapper {
  display: flex;
  justify-content: center;
}

.information-section {
  text-align: center;
}

.information-title {
  border-bottom: solid 1px black;
  padding-bottom: 5px;
}

.information-subtitle {
  font-family: oswaldLight;
}

.rate-per-hour-section {
  display: flex;
  justify-content: center;
  margin: 30px 0 50px 0;
}

/* .inputs-section {
  display: flex;
  justify-content: center;
  align-items: flex-end;
} */

.categories-switch-wrapper {
  display: flex;
  align-items: center;
  margin: 0 20px;
}

.bottom-divider-wrapper {
  text-align: center;
}

.divider {
  border-bottom: solid 1px black;
  /* color: rgb(0, 132, 255); */
}
