@font-face {
  font-family: oswaldRegular;
  src: url("./fonts/Oswald/Oswald-Regular.ttf");
}

@font-face {
  font-family: oswaldExtraLight;
  src: url("./fonts/Oswald/Oswald-ExtraLight.ttf");
}

@font-face {
  font-family: oswaldLight;
  src: url("./fonts/Oswald/Oswald-Light.ttf");
}

@font-face {
  font-family: oswaldMedium;
  src: url("./fonts/Oswald/Oswald-Medium.ttf");
}

@font-face {
  font-family: oswaldSemiBold;
  src: url("./fonts/Oswald/Oswald-SemiBold.ttf");
}

@font-face {
  font-family: oswaldBold;
  src: url("./fonts/Oswald/Oswald-Bold.ttf");
}

.App {
  font-family: oswaldRegular;
  margin: 0;
}

::-webkit-scrollbar {
  width: 0;
}
